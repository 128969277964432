import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import '../styles/navbar.css'
import icon1 from '../assets/icons/linkedIn.svg'
import icon2 from '../assets/icons/facebook.svg'
import icon3 from '../assets/icons/Instagram.svg'
import icon4 from '../assets/icons/github.svg'
import icon5 from '../assets/icons/icons8-github.svg'


const NavBar = ()=>{
    const [scrolled, setScrolled] = useState(false)

    useEffect(()=>{
        const onScroll = () => {
            if (window.scrollY > 50) {
              setScrolled(true);
            } else {
              setScrolled(false);
            }
          }
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener("scroll", onScroll);
    },[])

    return(
        <nav className={scrolled?"scrolled": ""}>
            <h2>ICON</h2>
                <ul>
                    <HashLink to='#home'>
                        <li>Home</li>
                    </HashLink>
                    <HashLink to='#skills'>
                        <li>Skills</li>
                    </HashLink>
                    <HashLink to='#about'>
                        <li>About</li>
                    </HashLink>
                    <HashLink to='#project'>
                        <li>Projects</li>
                    </HashLink>
                    <div id="btn-social">
                        <a href="https://www.linkedin.com/in/frank-aguado-l-7a8888167/">
                            <div className="social-media">
                                    <img src={icon1} alt=""/>                             
                            </div>    
                        </a>
                        <a href="https://github.com/FJosseph">
                            <div className="social-media">
                                    <img src={icon5} alt=""/>                             
                            </div>    
                        </a>
                        <a href="https://github.com/FJosseph">
                            <div className="social-media">
                                    <img src={icon3} alt=""/>                             
                            </div>    
                        </a>
                                
                    </div>
                    <HashLink className="button" to="#contact">
                        Let's Contact
                    </HashLink>
                </ul>
        </nav>
    )
}

export default NavBar