import React, { useState } from "react";
import '../styles/footer.css'
import '../styles/suscribe.css'
import facebook from '../assets/icons/facebook.svg'
import instagram from '../assets/icons/Instagram.svg'
import linkedIn from '../assets/icons/linkedIn.svg'
import github from '../assets/icons/github.svg'
import { Form, Row } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import icon1 from '../assets/icons/linkedIn.svg'
import icon2 from '../assets/icons/facebook.svg'
import icon3 from '../assets/icons/Instagram.svg'

const Footer = ()=>{
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
    };
    return(
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                <section className="wrapper">
                    <div className="content">
                        <header>
                        <h1>Subscribe</h1>
                        </header>
                        <footer>
                        <form>
                            <input type="email" placeholder="Enter your email"/>
                            <button type="submit">Let's go</button>                            
                        </form>
                        </footer>
                    </div>
                </section>
                </div>
            </div>
            <p>Copyright 2022. All Rights Reserved</p>
        </footer>
    )
}

export default Footer