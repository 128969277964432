import React from "react";
import '../styles/skills.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HTML from '../assets/icons/skills/HTML.svg'
import css from '../assets/icons/skills/Css.svg'
import js from '../assets/icons/skills/JavaScript.svg'
import node from '../assets/icons/skills/nodejs-1.svg'
import express from '../assets/icons/skills/express-109.svg'
import react from '../assets/icons/skills/react-2.svg'
import git from '../assets/icons/skills/git_plain_wordmark_logo_icon_146508.svg'
import mongo from '../assets/icons/skills/mongodb-ar21.svg'
import postgres from '../assets/icons/skills/postgresql.svg'
import redux from '../assets/icons/skills/redux.svg'
import mysql from '../assets/icons/skills/mysql-official.svg'
import wordpress from '../assets/icons/skills/wordpress-blue.svg'

const Skills = ()=>{
    const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
    return(
        <section id="skills">
            <div id="box-skills">
                <h2>SKILLS</h2> 
                <p>These are some of the technologies I currently use.</p>
                <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                    <div className="icon-carousel">
                        <img src={HTML} alt=""/>
                    </div>
                    <div className="icon-carousel">
                        <img src={css} alt=""/>
                    </div>
                    <div className="icon-carousel">
                        <img src={js} alt=""/>
                    </div>
                    <div className="icon-carousel">
                        <img  src={react} alt=""/>
                    </div>
                    <div className="icon-carousel">
                        <img  src={redux} alt=""/>
                    </div>
                    <div className="icon-carousel">
                        <img  src={node} alt=""/>
                    </div>
                    <div className="icon-carousel">
                        <img  src={git} alt=""/>
                    </div>
                    <div className="icon-carousel">
                        <img  src={mongo} alt=""/>
                    </div> 
                    <div className="icon-carousel">
                        <img  src={postgres} alt=""/>
                    </div> 
                    <div className="icon-carousel">
                        <img  src={mysql} alt=""/>
                    </div> 
                    <div className="icon-carousel">
                        <img  src={git} alt=""/>
                    </div>
                    <div className="icon-carousel">
                        <img src={wordpress} alt=""/>
                    </div>
                </Carousel>
            </div>
            <div id="box1" className="box-glass"></div>
            <div id="box2" className="box-glass"></div>
        </section>
    )
}

export default Skills