import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useState } from 'react';
import axios from 'axios';
const URL = process.env.REACT_APP_SERVER || 'http://localhost:3001'


function FormContact() {
  const [input, setInput] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: ''
  })
  const handleInput = (e)=>{
    setInput({
      ...input,
      [e.target.name] : e.target.value
    })
  }
  const handleErrors = Object.values(input).filter(x=>x === '')
  const handleSubmit = async (e)=>{
    if(handleErrors.length) alert('Faltan rellenar algunos campos')
    e.preventDefault()
    const response = await axios.post('https://contact-server.onrender.com/contact', input)
    return response.status === 200 ? alert('Email send satisfactory') : alert("Sorry, your mail haven't send")
  }

  return (
    <Form onSubmit={handleSubmit} className="form-c">
      <h2>GET IN TOUCH</h2>
      <Row className="g-2">
        <Col size={12} md>
          <FloatingLabel controlId="floatingInputGrid" label="First Name">
            <Form.Control required name="firstname" type="text" value={input.firstname} placeholder="name@example.com" maxLength="14" onChange={(e)=>handleInput(e)}/>
          </FloatingLabel>
        </Col>
        <Col size={12} md>
          <FloatingLabel controlId="floatingInputGrid" label="Last Name">
            <Form.Control required name="lastname" type="text" value={input.lastname} placeholder="name@example.com" maxLength="14" onChange={(e)=>handleInput(e)}/>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="g-2">
        <Col size={12} md>
          <FloatingLabel controlId="floatingInputGrid" label="Email">
            <Form.Control required name="email" type="email" value={input.email} placeholder="name@example.com" onChange={(e)=>handleInput(e)}/>
          </FloatingLabel>
        </Col>
        <Col size={12} md>
          <FloatingLabel controlId="floatingInputGrid" label="Phone">
            <Form.Control required name="phone" type="tel"value={input.phone} placeholder="name@example.com" maxLength="11" onChange={(e)=>handleInput(e)}/>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="g-2">
          <FloatingLabel id='message-input' controlId="floatingInputGrid" label="Message">
            <Form.Control required name="message" as="textarea" value={input.message} rows={3} placeholder="name@example.com" onChange={(e)=>handleInput(e)}/>
          </FloatingLabel>
      </Row>
        <input id='btn-submit' type="submit" name="submit" value="Send"/>
    </Form>
  );
}

export default FormContact;