import React from "react";
import { HashLink } from "react-router-hash-link";
import '../styles/banner.css'
import { Link } from 'react-router-dom'
import pdf from '../assets/CV/CV-Aguado-Licas-Frank-Josseph.pdf'

export default function Banner() {
    return(
        <section className="banner" id="home">
            <div id="text-home">
                <h1>Hello! I'm Frank</h1>
                <p>I am focused on personal growth and the environment that he obtained in some projects directly related to the development of web applications in the front-end and back-end, always maintaining the direct purpose of performance, dedication and discipline. Willing to constantly learn, always maintaining perseverance and responsibility when working. I like teamwork and I am passionate about full-stack development.
</p>
                <HashLink className="button" to="#contact">
                    Contact me!
                </HashLink>
                <a href={pdf} download ="Aguado-Frank.pdf" style={{border:"none", "borderRadius": 0 }} id="downCV">Download CV</a>
            </div>
            <div id="image-banner">
                <img src="http://revampwebsol.com/wp-content/uploads/2021/05/plugin.gif" alt="dev" />
            </div>
        </section>
    )    
}