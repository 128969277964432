import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import image from '../assets/image/image programmer.png'
import '../styles/contact.css'
import FormContact from './FormContact'


const Contact = ()=>{
    return(
        <section id="contact">
            <div className="contact-box" id="image-contact">
                <img src={image} alt="" />
            </div>
            <div className="contact-box" id="form-contact">
                <FormContact/>
            </div>
        </section>
    )
}

export default Contact